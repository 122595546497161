<template>
  <div class="box">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>售后订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>维修单审批记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div class="nav">
        <div class="main">
          <div class="titles">订单编号</div>
          <el-input style="width: 300px" placeholder="请输入订单编号" v-model="ticket_order_no" class="input-with-select" clearable @clear="handleEmptyno">
            <el-button slot="append" icon="el-icon-search" @click="ticketOrdernofun()"></el-button>
          </el-input>
        </div>
        <div class="main">
          <div class="titles">维修工单编号</div>
          <el-input style="width: 300px" placeholder="请输入工单编号" v-model="work_order_no" class="input-with-select" clearable @clear="handleEmptywork">
            <el-button slot="append" icon="el-icon-search" @click="worknofun()"></el-button>
          </el-input>
        </div>

        <div class="main">
          <div class="titles">日期筛选</div>
          <el-date-picker
            @change="gettime"
            v-model="timeFrom"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>

      <div class="nav">
        <div class="main">
          <div class="titles">客户名称</div>
          <el-input style="width: 300px" placeholder="请输入客户名称" v-model="customer_name" class="input-with-select" clearable @clear="handleEmptyname">
            <el-button slot="append" icon="el-icon-search" @click="ticketnamefun()"></el-button>
          </el-input>
        </div>

        <div class="main">
          <div class="titles">服务工程师</div>
          <div>
            <!-- <el-select v-model="maintain_user" placeholder="未选择" @clear="handleEmptyuser" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="choicedrug(item)"></el-option>
            </el-select> -->
            <el-autocomplete
              clearable
              @clear="handleEmptyuser"
              :trigger-on-focus="false"
              placeholder="请输入工程师名称"
              v-model="gongchengs"
              :fetch-suggestions="shenvalidateCounts"
              value-key="name"
              @select="shenhandleSelect($event, '申请人')"
            ></el-autocomplete>
          </div>
        </div>
        <div class="main">
          <div class="titles">维修类型</div>
          <el-select v-model="maintain_type" placeholder="请选择" clearable @clear="handleEmptytype">
            <el-option v-for="item in types" :key="item.value" :label="item.name" :value="item.value" @click.native="typechenge(item)"></el-option>
          </el-select>
        </div>
      </div>

      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="ticket_order_no" label="订单编号" width="180"></el-table-column>
        <el-table-column prop="work_order_no" label="维修单编号" width="200"></el-table-column>
        <el-table-column prop="created_time" label="维修时间" width="150" align="center"></el-table-column>
        <el-table-column prop="maintain_user" label="服务工程师" align="center" width="150"></el-table-column>
        <el-table-column prop="customer_name" label="客户名称" width="220" align="center"></el-table-column>
        <el-table-column prop="maintain_type_desc" label="维修类型" width="150" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="维修设备" width="180" align="center"></el-table-column>
        <el-table-column prop="fault_desc" label="维修事项" align="center" width="150"></el-table-column>
        <el-table-column label="报价金额(元)" align="center" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.amount / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-view" @click="detail(scope.row.work_order_id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <!-- 详情 -->
    <!-- 维修工单详情 -->
    <el-dialog width="50%" title="售后工单完成详情" :visible.sync="innerVisible" center>
      <el-form :model="worklist">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="服务工程师" label-width="125px">
              <div class="el-select"><el-input v-model="worklist.order_user_name" disabled></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="维修时间" label-width="125px">
              <div class="el-select"><el-input v-model="worklist.create_time" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="维修设备名称" label-width="125px">
              <div class="el-select"><el-input v-model="worklist.device_name" disabled></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="维修设备生产日期" label-width="125px">
              <div class="el-select"><el-input v-model="worklist.device_date" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="故障描述" label-width="125px">
              <div class="el-select"><el-input type="textarea" :rows="2" v-model="worklist.fault_desc" disabled></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="维修方案" label-width="125px">
              <div class="el-select"><el-input type="textarea" :rows="2" v-model="worklist.maintain_scheme" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="故障照片" label-width="125px">
              <el-image
                :z-index="5000"
                style="width: 100px; height: 100px"
                v-for="(item, index) in worklist.fault_images"
                :key="index"
                :src="item"
                :preview-src-list="worklist.fault_images"
              ></el-image>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="完成图片" label-width="125px">
              <el-image
                :z-index="5000"
                style="width: 100px; height: 100px"
                v-for="(item, index) in worklist.maintain_images"
                :key="index"
                :src="item"
                :preview-src-list="worklist.maintain_images"
              ></el-image>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="hint">货物消耗</div>
      <el-table :data="worklist.goods_items" border style="width: 85%; margin-left: 10%">
        <el-table-column prop="goods_no" label="编号" width="130" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="货物名称" width="200" align="center"></el-table-column>
        <el-table-column prop="goods_desc" label="货物详情" align="center" width=""></el-table-column>
        <el-table-column label="单价(元)" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.goods_price / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_num" label="数量" align="center"></el-table-column>
        <el-table-column label="小计(元)" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.amount / 100 }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="tongs" style="hight: 30px">总计{{ worklist.amount / 100 }}元</div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="innerVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="innerVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Drop } from '../../../api/slogong'
import { Getren } from '../../../api/shouhuo.js'
import Fenye from '../../../components/Fenye'
import { Workorderlist, Workdetail } from '../../../api/afterOrder.js'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      caigoulist: [],
      gongchengs: '',
      renlist: [],
      worklist: {},
      tableData: [],
      pageData: {
        page: 1,
        size: 10,
        count: 0
      },
      innerVisible: false,
      types: [
        { name: '合同维修', value: 'contract' },
        { name: '临时维修', value: 'temp' }
      ],
      timeFrom: [], // 日期数组
      ticket_order_no: '',
      work_order_no: '',
      maintain_type: '',
      customer_name: '',
      maintain_user: '',
      from_time: '',
      end_time: ''
    }
  },
  mounted() {
    this.orderlists()
    this.Getrenlist()
  },
  methods: {
    // 搜索工程师后的数据
    shenvalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 选中工程师
    shenhandleSelect(e) {
      this.maintain_user = e.value
      this.orderlists()
    },

    handleEmptyno() {
      this.ticket_order_no = ''
      this.orderlists()
    },

    handleEmptyname() {
      this.customer_name = ''
      this.orderlists()
    },

    handleEmptywork() {
      this.work_order_no = ''
      this.orderlists()
    },
    // 客户名称
    ticketnamefun() {
      if (this.customer_name === '') {
        this.$message({
          message: '请输入客户名称',
          type: 'warning'
        })
      } else {
        this.orderlists()
      }
    },

    // 订单编辑搜索
    ticketOrdernofun() {
      if (this.ticket_order_no === '') {
        this.$message({
          message: '请输入订单编号',
          type: 'warning'
        })
      } else {
        this.orderlists()
      }
    },

    // 工单编号搜索
    worknofun() {
      if (this.work_order_no === '') {
        this.$message({
          message: '请输入工单编号',
          type: 'warning'
        })
      } else {
        this.orderlists()
      }
    },

    // 维修类型
    typechenge(item) {
      this.orderlists()
    },

    // 服务工程师
    choicedrug() {
      console.log(this.maintain_user)
      this.orderlists()
    },

    // 日期选中事件
    gettime(timeFrom) {
      // console.log(timeFrom)
      if (timeFrom !== null) {
        this.from_time = timeFrom ? timeFrom[0] : ''
        this.end_time = timeFrom ? timeFrom[1] : ''
        this.orderlists()
      } else {
        this.from_time = ''
        this.end_time = ''
        this.orderlists()
      }
    },

    // 清空
    handleEmpty() {
      //   console.log('wwww')
      this.ticket_order_no = ''
      this.work_order_no = ''

      this.customer_name = ''
      this.orderlists()
    },

    handleEmptyuser() {
      this.gongchengs = ''
      this.maintain_user = ''
      this.orderlists()
    },

    handleEmptytype() {
      this.maintain_type = ''
      this.orderlists()
    },

    // 详情
    detail(id) {
      this.Workdetails(id)
      this.innerVisible = true
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.orderlists()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.orderlists()
    },

    // 临时维修
    async orderlists() {
      const { data } = await Workorderlist({
        ...this.pageData,
        ticket_order_no: this.ticket_order_no,
        work_order_no: this.work_order_no,
        customer_name: this.customer_name,
        maintain_user: this.maintain_user,
        maintain_type: this.maintain_type,
        from_time: this.from_time,
        end_time: this.end_time
      })
      //   console.log(data)
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 新售后工单详情
    async Workdetails(order_id) {
      const { data } = await Workdetail({ order_id: order_id })
      // console.log(data)
      this.worklist = data.data
    },

    // 工程师
    async Getrenlist() {
      const { data } = await Getren()
      this.renlist = data.data
    },

    // 工程师
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.caigoulist = data.data
      cd(data.data)
    }
  }
}
</script>

<style scoped lang='less'>
.tongs {
  width: 85%;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ebeef5;
  margin-left: 10%;
  box-sizing: border-box;
  padding-left: 2%;
}
.hint {
  width: 100%;
  font-size: 16px;
  color: red;
  box-sizing: border-box;
  padding-left: 10%;
  font-weight: bold;
}
.nav {
  width: 100%;
  display: flex;
  //   justify-content: space-between;
  box-sizing: border-box;
  height: 50px;
  .main {
    width: 30%;
    // border: 1px solid #000;
    height: 100%;
    margin-left: 5px;
    display: flex;
    align-items: center;
    .titles {
      width: 17%;
      font-size: 13px;
    }
  }
}
</style>